export class ShortInterestRate {
    symbol: string;
    cusip: string;
    correspondentCode: string;
    office: string;
    accountNumber: string;
    quantity: number;
    rate: number;
    date: Date;
    lpit: number;
    slsavg: number;
    lpDate: Date;
    modifiedBy : string;
    rowId: string;


    constructor(shortRate?) {
        this.symbol = shortRate.symbol;
        this.cusip = shortRate.cusip;
        this.rate = shortRate.rate;
        this.date = shortRate.date;
        this.correspondentCode = shortRate.correspondentCode;
        this.office = shortRate.office;
        this.accountNumber = shortRate.accountNumber;
        this.lpit = shortRate.lpit;
        this.slsavg = shortRate.slsavg;
        this.quantity= shortRate.quantity;
        this.modifiedBy = shortRate.modifiedBy;
        this.rowId = shortRate.symbol + shortRate.accountNumber;
    }
}
