export const navigation = [
    {
        'id': 'contracts',
        'title': 'Trading',
        'type': 'group',
        'icon': 'attach_money',
        'permission': '',
        'children': [
            {
                'id': 'combined-contracts',
                'title': 'Contract Summary',
                'type': 'item',
                'icon': 'horizontal_split',
                'url': '/combined-contracts',
                'permission': '',
                'requiresSwitcher': true
            },
            {
                'id': 'contract_details',
                'title': 'Contract Details',
                'type': 'item',
                'icon': 'list',
                'url': '/contract-details',
                'permission': '',
                'requiresSwitcher': true
            },
            // {
            //     'id': 'contract_import',
            //     'title': 'Contract Import',
            //     'type': 'item',
            //     'icon': 'import_export',
            //     'url': '/import',
            //     'permission': '',
            //     'requiresSwitcher': true
            // },
            {
                'id': 'contractManagement',
                'title': 'Contract Management',
                'type': 'item',
                'icon': 'money',
                'url': '/contract-management',
                'permission': '',
                'requiresSwitcher': true
            },
            {
                'id': 'bulk_import',
                'title': 'Bulk Import',
                'type': 'item',
                'icon': 'import_export',
                'url': '/bulk',
                'permission': '',
                'requiresSwitcher': true
            },
            {
                'id': 'lcor',
                'title': 'LCOR',
                'type': 'item',
                'icon': 'dynamic_feed',
                'url': '/lcor',
                'permission': 'manageLcor',
                'requiresSwitcher': true
            },
            // {
            //     'id': 'transaction_history',
            //     'title': 'Transaction History',
            //     'type': 'item',
            //     'icon': 'history',
            //     'url': '/transaction-history',
            //     'permission': '',
            //     'requiresSwitcher': true
            // },
            {
                'id': 'contract_review',
                'title': 'Contract Review',
                'type': 'item',
                'icon': 'rate_review',
                'url': '/review',
                'permission': 'reviewContract',
                'requiresSwitcher': true
            },
        ]
    },
    {
        'id': 'dtc',
        'title': 'DTC',
        'type': 'group',
        'icon': 'business_center',
        'permission': '',
        'children': [
            {
                'id': 'dtc_combined_summary',
                'title': 'DTC Summary',
                'type': 'item',
                'icon': 'business_center',
                'url': '/dtc/combined-summary',
                'permission': '',
                'requiresSwitcher': true
            },
            // {
            //     'id': 'dtc_box',
            //     'title': 'DTC Summary',
            //     'type': 'item',
            //     'icon': 'business_center',
            //     'url': '/dtc/summary',
            //     'permission': '',
            // },
            {
                'id': 'dtc_transactions',
                'title': 'DTC Transactions',
                'type': 'item',
                'icon': 'list',
                'url': '/dtc/transactions',
                'permission': '',
                'requiresSwitcher': true
            },
            {
                'id': 'seg_memoseg',
                'title': 'Memoseg',
                'type': 'item',
                'icon': 'lock',
                'url': '/seg-memoseg',
                'permission': 'manageMemoseg',
                'requiresSwitcher': true
            }
        ]
    },
    {
        'id': 'operations',
        'title': 'Operations',
        'type': 'group',
        'icon': 'public',
        'permission': '',
        'children': [
            {
                'id': 'entities',
                'title': 'Entities',
                'type': 'item',
                'icon': 'location_city',
                'url': '/entities',
                'permission': 'Entity Admin',
                'requiresSwitcher': true
            },
            {
                'id': 'counterparties',
                'title': 'Counterparties',
                'type': 'item',
                'icon': 'domain',
                'url': '/counterparties',
                'permission': 'Entity Admin',
                'requiresSwitcher': true
            },
            // {
            //     'id': 'accounts',
            //     'title': 'Accounts',
            //     'type': 'item',
            //     'icon': 'business_center',
            //     'url': '/accounts'
            // },
            {
                'id': 'users',
                'title': 'Users',
                'type': 'item',
                'icon': 'people',
                'url': '/users',
                'permission': 'manageUsers',
                'requiresSwitcher': true
            },
            // {
            //     'id': 'instruments',
            //     'title': 'Instruments',
            //     'type': 'item',
            //     'icon': 'edit',
            //     'url': '/instruments',
            //     'permission': 'manageInstruments',
            //     'requiresSwitcher': true
            // },
            {
                'id': 'shortRates',
                'title': 'Short Rate Adjustment',
                'type': 'item',
                'icon': 'price_check',
                'url': '/shortrates',
                'permission': '',
                'requiresSwitcher': true
            },
            {
                'id': 'securityMaster',
                'title': 'Security Master',
                'type': 'item',
                'icon': 'edit',
                'url': '/securitymaster',
                'permission': '',
                'requiresSwitcher': true
            },
            {
                'id': 'lendingPit',
                'title': 'Lending Pit Lookup',
                'type': 'item',
                'icon': 'money',
                'url': '/lendingpit',
                'permission': '',
                'requiresSwitcher': true
            },
        ]
    },
    {
        'id': 'FPL',
        'title': 'Fully Paid Lending',
        'type': 'group',
        'icon': 'new_releases',
        'permission': 'fplView',
        'children': [
            {
                'id': 'fpl_accounts',
                'title': 'FPL Accounts',
                'type': 'item',
                'icon': 'business',
                'url': '/fpl/accounts',
                'permission': '',
                'requiresSwitcher': true
            },
            {
                'id': 'fpl_account_positions',
                'title': 'FPL Positions',
                'type': 'item',
                'icon': 'list_alt',
                'url': '/fpl/accountPositions',
                'permission': '',
                'requiresSwitcher': true
            }
        ]
    },
    {
        'id': 'Reporting',
        'title': 'Reporting',
        'type': 'group',
        'icon': 'new_releases',
        'permission': '',
        'children': [
            {
                'id': 'reports',
                'title': 'Reports',
                'type': 'item',
                'icon': 'insert_chart_outlined',
                'url': '/reports',
                'permission': '',
                'requiresSwitcher': true
            },
    ]},

    // {
    //     'id': 'risk',
    //     'title': 'Risk',
    //     'type': 'group',
    //     'icon': 'warning',
    //     'permission': 'betaFeatures',
    //     'children': [
    //         {
    //             'id': 'counterparty_limits',
    //             'title': 'Counterparty Limits',
    //             'type': 'item',
    //             'icon': 'domain',
    //             'url': '/risk/counterparty-limits',
    //             'permission': '',
    //             'requiresSwitcher': false
    //         }
    //     ]
    // },
    // {
    //     'id': 'Releases',
    //     'title': 'Releases',
    //     'type': 'group',
    //     'icon': 'new_releases',
    //     'permission': '',
    //     'children': [
    //         {
    //             'id': 'release_notes',
    //             'title': 'Release Notes',
    //             'type': 'item',
    //             'icon': 'new_releases',
    //             'url': '/release_notes',
    //             'permission': '',
    //             'requiresSwitcher': true
    //         },
    //     ]
    // },
    {
        'id': 'Beta',
        'title': 'Beta Features',
        'type': 'group',
        'icon': 'new_releases',
        'permission': 'betaFeatures',
        'children': [
            {
                'id': 'fxcontract_details',
                'title': 'Fx Contract Details',
                'type': 'item',
                'icon': 'list',
                'url': '/fxcontract-details',
                'permission': '',
                'requiresSwitcher': true
            },
            // {
            //     'id': 'buy-ins',
            //     'title': 'Buy-Ins',
            //     'type': 'item',
            //     'icon': 'list',
            //     'url': '/buy-ins',
            //     'permission': '',
            //     'requiresSwitcher': true
            // },
            {
                'id': 'shortInterestRates',
                'title': 'Short Interest Rate Adjustment',
                'type': 'item',
                'icon': 'price_check',
                'url': '/shortInterestRates',
                'permission': '',
                'requiresSwitcher': true
            },
        ]
    }


];
