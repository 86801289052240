import {Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {ShortInterestRatesService} from './shortInterestRates.service';


@Component({
    selector: 'app-shortInterest-rates',
    templateUrl: './shortInterestRates.component.html',
    styleUrls: ['./shortInterestRates.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ShortInterestRatesComponent implements OnInit, OnDestroy {

    @Input() entityId;

    constructor(public shortInterestRatesService: ShortInterestRatesService,
                @Inject(LOCALE_ID) private locale: string) {
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.shortInterestRatesService.editedShortRates = [];
    }

}
