// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//
// export const environment = {
//     production: true,
//     hmr       : false,
//     firebase: {
//         apiKey: "AIzaSyAyltPgx9s9syLrCO03ByiLXLoO3wxYuzA",
//         authDomain: "vcl-stockloan-dev.firebaseapp.com",
//         databaseURL: "https://vcl-stockloan-dev.firebaseio.com",
//         projectId: "vcl-stockloan-dev",
//         storageBucket: "vcl-stockloan-dev.appspot.com",
//         messagingSenderId: "796213062059",
//         appId: "1:796213062059:web:c57ab367cb8da9f3af4aee"
//     },
//      verticalApiEndpoint: 'https://ngapi-dot-vcl-stockloan-dev.appspot.com/_ah/api/vertical/v1/'
// };

// export const environment = {
//     production: true,
//     hmr       : false,
//     firebase: {
//         apiKey: "AIzaSyCCzDxzdDa1m_KntFKo8RHYF_XlZSIlQoM",ng
//         authDomain: "vc-sls-sandbox.firebaseapp.com",
//         projectId: "vc-sls-sandbox",
//         storageBucket: "vc-sls-sandbox.appspot.com",
//         messagingSenderId: "966681470441",
//         appId: "1:966681470441:web:5e5542fe3946ef4627d778",
//         measurementId: "G-CWVH5QYNX0"
//
//     },
//     verticalApiEndpoint: 'https://ngapi-dot-vc-sls-sandbox.appspot.com/_ah/api/vertical/v1/',
//     title: 'Stockloan'
// };



// export const environment = {
//     production: true,
//     hmr       : false,
//     firebase: {
//         apiKey: "AIzaSyCHj_BJ-fNV6th5A6LFQ9s7hhZ-fkiFBHQ",
//         authDomain: "wd-sls-prod.firebaseapp.com",
//         databaseURL: "https://wd-sls-prod.firebaseio.com",
//         projectId: "wd-sls-prod",
//         storageBucket: "wd-sls-prod.appspot.com",
//         messagingSenderId: "762564926681",
//         appId: "1:762564926681:web:e2348300e595d8a84db2c8"
//
//     },
//     verticalApiEndpoint: 'https://ngapi-dot-wd-sls-prod.appspot.com/_ah/api/vertical/v1/',
//     title: 'WILSON-DAVIS & CO., INC. Stockloan'
// };
//



// export const environment = {
//     production: true,
//     hmr       : false,
//     firebase: {
//         apiKey: 'AIzaSyB2VmJMdvxc_Q4J3mLz9tdoEQ01Sm9DMHI',
//         authDomain: 'fcn-verticaldev.firebaseapp.com',
//         databaseURL: 'https://fcn-verticaldev.firebaseio.com',
//         projectId: 'fcn-verticaldev',
//         storageBucket: 'fcn-verticaldev.appspot.com',
//         messagingSenderId: '733625405676'
//     },
//     verticalApiEndpoint: 'https://ngapi-dot-fcn-verticaldev.appspot.com/_ah/api/vertical/v1/'
// };




//
//
//
// export const environment = {
//     production: true,
//     hmr       : false,
//     firebase: {
//         apiKey: "AIzaSyCXlloNJ8qzztpzBAovNSwBQ3Nl1Jrml1g",
//         authDomain: "vct-sls-dev.firebaseapp.com",
//         databaseURL: "https://vct-sls-dev.firebaseio.com",
//         projectId: "vct-sls-dev",
//         storageBucket: "vct-sls-dev.appspot.com",
//         messagingSenderId: "297174779804",
//         appId: "1:297174779804:web:5607b48d80722e49d6aa59"
//
//     },
//     verticalApiEndpoint: 'https://ngapi-dot-vct-sls-dev.appspot.com/_ah/api/vertical/v1/',
//     title: 'Stockloan'
// };





export const environment = {
    production: true,
    hmr       : false,
    firebase: {
        apiKey: "AIzaSyAgKmphU2mBybY8lPvf_PsBA2tIWhj0vg0",
        authDomain: "vcl-stockloan-prod.firebaseapp.com",
        databaseURL: "https://vcl-stockloan-prod.firebaseio.com",
        projectId: "vcl-stockloan-prod",
        storageBucket: "vcl-stockloan-prod.appspot.com",
        messagingSenderId: "743694002428",
        appId: "1:743694002428:web:8262aa4e83dbb957574f79"

    },
    verticalApiEndpoint: 'https://ngapi-dot-vcl-stockloan-prod.appspot.com/_ah/api/vertical/v1/',
    title: 'Velocity Clearing LLC Stockloan'
};



// export const environment = {
//     production: true,
//     hmr       : false,
//     firebase: {
//         apiKey: 'AIzaSyAtuCANhHsqqQ0Jf1e8B6UaUNebQ3weovI',
//         authDomain: 'fcn-velocity.firebaseapp.com',
//         databaseURL: 'https://fcn-velocity.firebaseio.com',
//         projectId: 'fcn-velocity',
//         storageBucket: 'fcn-velocity.appspot.com',
//         messagingSenderId: '954649917918'
//     },
//     verticalApiEndpoint: 'https://ngapi-dot-fcn-velocity.appspot.com/_ah/api/vertical/v1/',
//     title: 'Velocity Clearing LLC Stockloan'
// };


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
