import { Component } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

import { navigation } from "app/navigation/navigation";
import { AngularFireAuth } from "angularfire2/auth";
import { UserService } from "../../auth/user/user.service";
import { ContractsService } from "../content/trading/contracts/contracts.service";
import { NavTabService } from "../nav-tab/nav-tab.service";
import { NavTabItem } from "../nav-tab/nav-tab-item";

@Component({
    selector: "fuse-toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
})
export class FuseToolbarComponent {
    userStatusOptions: any[];
    languages: any;
    selectedLanguage: any;
    showLoadingBar: boolean;
    horizontalNav: boolean;
    noNav: boolean;
    navigation: any;

    constructor(
        private router: Router,
        private fuseConfig: FuseConfigService,
        private sidebarService: FuseSidebarService,
        private translate: TranslateService,
        private afAuth: AngularFireAuth,
        public userService: UserService,
        public contractService: ContractsService,
        public navTabService: NavTabService,
    ) {
        this.userStatusOptions = [
            {
                title: "Online",
                icon: "icon-checkbox-marked-circle",
                color: "#4CAF50",
            },
            {
                title: "Away",
                icon: "icon-clock",
                color: "#FFC107",
            },
            {
                title: "Do not Disturb",
                icon: "icon-minus-circle",
                color: "#F44336",
            },
            {
                title: "Invisible",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#BDBDBD",
            },
            {
                title: "Offline",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#616161",
            },
        ];

        this.languages = [
            {
                id: "en",
                title: "English",
                flag: "us",
            },
            {
                id: "tr",
                title: "Turkish",
                flag: "tr",
            },
        ];

        this.selectedLanguage = this.languages[0];

        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.showLoadingBar = true;
            }
            if (event instanceof NavigationEnd) {
                this.showLoadingBar = false;
            }
        });

        this.fuseConfig.onConfigChanged.subscribe((settings) => {
            this.horizontalNav = settings.layout.navigation === "top";
            this.noNav = settings.layout.navigation === "none";
        });

        this.navigation = navigation;
    }

    toggleSidebarOpened(key) {
        this.sidebarService.getSidebar(key).toggleOpen();
    }

    search(value) {
        // Do your search here...
        //console.log(value);
    }

    logout() {
        this.userService.logout();
    }

    userPreferences() {
        const navTabItem = new NavTabItem(
            "accounts",
            "User Preferences",
            "item",
            "settings",
            "/preferences",
            false,
        );

        this.navTabService.addTab(navTabItem);
    }

    navToResetPassword() {
        const navTabItem = new NavTabItem(
            "accounts",
            "Reset password",
            "item",
            "settings",
            "/change-password",
            false,
        );

        this.navTabService.addTab(navTabItem);
    }

    name(): string {
        return this.userService.getUserNickName();
    }

    setLanguage(lang) {
        // Set the selected language for toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this.translate.use(lang.id);
    }

    async openQuickSymbolSummary(input: string) {
        this.contractService.isLoading = true;
        if (input && input.length > 0) {
            await this.contractService.toggleQuickSymbolSummaryWindow(input);
        } else {
            this.contractService.showTradeForm = false;
            this.contractService.showQuickSymbolSummary = false;
        }
        this.contractService.isLoading = false;
    }
    textInput(value: string) {
        return value.toUpperCase();
    }
}
