import {Component, Inject, Input, LOCALE_ID, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {UserService} from '../../../../auth/user/user.service';
import {ShortInterestRatesService} from './shortInterestRates.service';
import {GridOptions, RowNode} from 'ag-grid-community';
import {formatCurrency} from '@angular/common';

@Component({
    selector: 'app-shortInterestRates-list',
    templateUrl: './shortInterestRates-list.component.html',
    styleUrls: ['./shortInterestRates-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})

export class ShortInterestRatesListComponent implements OnInit {

    columnDefs: any[];
    gridApi: any;
    private suppressCellValueChangedEvent = false;

    defaultColDef = {
        sortable: true,
        filter: true
    };

    gridOptions: GridOptions = <GridOptions>{};

    getRowStyle = (params) => {
        if (params.node.group) {
            return {'font-weight': 'bold'};
        }
    };

    constructor(public shortInterestRatesService: ShortInterestRatesService,
                public snackBar: MatSnackBar,
                @Inject(LOCALE_ID) private locale: string,
                public userService: UserService) {
    }

    ngOnInit() {
        this.shortInterestRatesService.getShortRates().then(rates => {
            this.columnDefs = [
                {headerName: 'Symbol', field: 'symbol', filter: 'agTextColumnFilter', width: 100, minWidth: 100, sortedAt: 1, sort: 'asc'},
                {headerName: 'Cusip', field: 'cusip', filter: 'agTextColumnFilter', width: 125, minWidth: 125},
                {headerName: 'Cor', field: 'correspondentCode', filter: 'agTextColumnFilter', width: 100, minWidth: 100},
                {headerName: 'Office', field: 'office', filter: 'agTextColumnFilter', width: 100, minWidth: 100},
                {headerName: 'Acct', field: 'accountNumber', filter: 'agTextColumnFilter', width: 100, minWidth: 150},
                {headerName: 'Quantity', field: 'quantity', filter: 'agTextColumnFilter', width: 100, minWidth: 100},
                {headerName: 'Price', field: 'closePrice', filter: 'agTextColumnFilter', width: 100, minWidth: 100},
                {headerName: 'SLSAvg', field: 'slsavg', filter: 'agTextColumnFilter', width: 100, minWidth: 100},
                {headerName: 'LPit', field: 'lpit', filter: 'agTextColumnFilter', width: 100, minWidth: 100},
                {
                    headerName: 'To IClear', field: 'rate', filter: 'agSetColumnFilter', width: 100, minWidth: 100,
                    editable: true
                },
                {
                    headerName: 'Interest', filter: 'agNumberColumnFilter', minWidth: 110, maxWidth: 110, width: 110,
                    valueGetter: (params) =>
                        (params.data.quantity * params.data.closePrice * params.data.rate / 100 / 360 * -1).toFixed(2),
                    comparator: (valueA, valueB) => {
                        return valueA - valueB;
                        }
                },
                {headerName: 'ModifiedBy', field: 'modifiedBy', filter: 'agTextColumnFilter', width: 100, minWidth: 100},

            ]
            ;
        });

        this.gridOptions = {
            enableRangeSelection: true,
        };

    }

    onGridReady(params) {
        this.gridApi = params.api;
    }

    getRowNodeId(data) {
        return data.rowId;
    }

    // cellEditingStopped(event) {
    //     if (event.value !== '') {
    //         //console.log(event);
    //         this.shortInterestRatesService.editedShortRates.push(event.data);
    //     }
    // }

    cellValueChanged(event) {
        if (!this.suppressCellValueChangedEvent) {
            if (this.gridApi.rangeController.cellRanges[0]) {
                this.suppressCellValueChangedEvent = true;
                let start = this.gridApi.rangeController.cellRanges[0].startRow.rowIndex;
                let end = this.gridApi.rangeController.cellRanges[0].endRow.rowIndex + 1;
                for (let i = start; i < end; i++) {
                    const rowNode = this.gridApi.getDisplayedRowAtIndex(i);
                    if (rowNode) {
                        rowNode.setDataValue('rate', event.newValue);
                        rowNode.setDataValue('modifiedBy', this.userService.getUserNickName());
                        this.shortInterestRatesService.editedShortRates.push(rowNode.data);
                    }
                }
                setTimeout(() => {
                    this.suppressCellValueChangedEvent = false; // Re-enable the event handler
                    this.gridApi.refreshCells();
                }, 0);
            }
        }
    }
}
