import {AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {formatCurrency, formatNumber} from '@angular/common';
import {ContractsService} from '../contracts/contracts.service';
import {UserService} from '../../../../auth/user/user.service';
import {ActivatedRoute} from '@angular/router';
import {RowNode} from 'ag-grid-community';
import {Contract} from '../contracts/contract.model';
import {DtcSummaryService} from '../../dtc/dtc-summary/dtc-summary.service';
import {NavTabService} from '../../../nav-tab/nav-tab.service';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import Split from 'split.js';
import {ContractFilterService} from '../../../../contract-filter/contract-filter.service';
import {ContractDetailPreferences} from '../contracts/contract-detail-preferences/contract-detail-preferences';
import {NavTabItem} from '../../../nav-tab/nav-tab-item';
import {TradeFormMode, TradeFormParams} from '../trade-form/TradeFormParams';
import {LendingpitCashRatesService} from '../../operations/lendingpit/lendingpit-cash-rates.service';
import {reduce} from 'rxjs/operators';

@Component({
    selector: 'combined-contracts',
    templateUrl: './combined-contracts.component.html',
    styleUrls: ['./combined-contracts.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CombinedContractsComponent implements OnInit, OnDestroy, AfterViewInit {

    private gridApi;
    gridColumnApi;
    public init = false;
    public collapse = false;

    user: any;

    rowSelection = 'single';

    onContractsAddedSubscription: Subscription;
    onContractsChangedSubscription: Subscription;
    onContractsRemovedSubscription: Subscription;
    onUserDataChangedSubscription: Subscription;
    onDepositoryNoSwitchedSubscription: Subscription;
    onShowDetailsSubscription: Subscription;
    onPositionModified: Subscription;
    onFilterChangedSubscription: Subscription;
    onEffectiveDateChanged: Subscription;
    tabId;
    splitControl;
    showDetailsPanel = false;
    dialogRef: any;
    selectedGrouping = 'cusip';
    pinnedBottomRowData;
    getRowStyle;
    selectedSymbol = '';
    columnDefs: any[] = [];

    autoGroupColumnDef = {
        headerName: 'Symbol',
        minWidth: 115,
        width: 115,
        maxWidth: 115,
        resizable: false,
        pinned: true,
        filter: 'agTextColumnFilter',
        field: 'symbol',
        cellRendererParams: {
            suppressCount: true,
            suppressDoubleClickExpand: true,
            suppressEnterExpand: true
        },
        valueFormatter: (params) => params.value
    };

    getContextMenuItems;
    sideBar = {
        toolPanels: ['columns'],
        defaultToolPanel: ''
    };

    defaultColDef = {};

    constructor(
        public contractsService: ContractsService,
        //public dtcPositionService: DtcSummaryService,
        public userService: UserService,
        public dialog: MatDialog,
        private httpClient: HttpClient,
        @Inject(LOCALE_ID) private locale: string,
        public navTabService: NavTabService,
        public activatedRoute: ActivatedRoute,
        public switcherService: SwitcherService,
        public filterService: ContractFilterService
    ) {

        this.defaultColDef = {
            enableCellChangeFlash: true,
            sortable: true,
            filter: true,
            resizable: true,
            cellStyle: CombinedContractsComponent.styleZero,
            floatingFilter: false,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
                color: 'red'
            }
        };

        this.getRowStyle = (params) => {
            if (params.node.rowPinned) {
                return {'font-weight': 'bold'};
            }
        };

        this.getContextMenuItems = function getContextMenuItems(params) {
            return [
                {
                    name: 'Export to CSV',
                    action: function () {
                        params.api.exportDataAsCsv({
                            shouldRowBeSkipped: function (row) {
                                if (!!row.node.data) {
                                    return true;
                                } else {
                                    // return (row.node.field === this.grouping);
                                    return row.node.level !== 0;
                                }
                            },
                            processCellCallback: function (row) {
                                if (typeof row.value === 'string') {
                                    let updateValue: string = row.value;
                                    updateValue = updateValue.replace(/->/g, ' ').trim();
                                    row.value = updateValue;
                                }
                                return row.value;
                            }
                        });
                    }
                },
                {
                    name: 'Export to Excel',
                    action: function () {
                        params.api.exportDataAsExcel({
                            shouldRowBeSkipped: function (row) {
                                if (!!row.node.data) {
                                    return true;
                                } else {
                                    // return (row.node.field === this.grouping);
                                    return row.node.level !== 0; // false; // row.api.selectedGrouping !== row.node.field;
                                }
                            },
                            processCellCallback: function (row) {
                                if (typeof row.value === 'string') {
                                    let updateValue: string = row.value;
                                    updateValue = updateValue.replace(/->/g, ' ').trim();
                                    row.value = updateValue;
                                }
                                return row.value;
                            }
                        });
                    }
                },
            ];
        };

        this.defineColumns();
    }

    ngOnInit() {
        this.onDepositoryNoSwitchedSubscription = this.switcherService.depositoryNoSwitched.subscribe(sub => {
            this.gridApi.setRowData(this.contractsService.currentCollection.filter((c => this.depositoryFilter(c))));
            this.gridApi.setFilterModel(this.filterService.currentFilter);
        });

        this.onEffectiveDateChanged = this.contractsService.onNewContractArray.subscribe(contracts => {
            if (this.onContractsAddedSubscription) {
                this.onContractsAddedSubscription.unsubscribe();
            }
            if (this.onContractsChangedSubscription) {
                this.onContractsChangedSubscription.unsubscribe();
            }
            if (this.onContractsRemovedSubscription) {
                this.onContractsRemovedSubscription.unsubscribe();
            }
            if (this.onUserDataChangedSubscription) {
                this.onContractsAddedSubscription.unsubscribe();
            }
            // if (this.onPositionModified) {
            //     this.onPositionModified.unsubscribe();
            // }

            if (this.contractsService.isEffectiveDateToday()) {
                this.setupContractSubscriptions();
            } else {
                this.gridApi.setRowData(contracts.filter((c => this.depositoryFilter(c))));
                this.gridApi.setFilterModel(this.filterService.currentFilter);
            }
        });

        this.activatedRoute.params.subscribe(params => {
            this.tabId = params['id'];
            let filter = this.navTabService.getFilter(this.tabId);
            if (this.gridApi) {
                this.gridApi.setFilterModel(filter);
            }
            if (filter) {
                this.filterService.setFilterControls(filter);
                this.filterService.currentFilter = filter;
            } else {
                this.filterService.clearFilterControls(false);
            }
        });

        this.onShowDetailsSubscription = this.filterService.showDetailsPanelEvent.subscribe(value => this.showDetails(value));

    }

    ngAfterViewInit(): void {
        this.applyTabPreferences();
    }

    applyTabPreferences() {
        let tabPrefs = this.navTabService.getTabPreferences(this.tabId);
        if (tabPrefs && tabPrefs['showDetails']) {
            this.showDetails(tabPrefs['showDetails']);
            this.filterService.setDetailToggle(tabPrefs['showDetails']);
        }
    }

    showDetails(value: boolean) {
        if (value) {
            this.showDetailsPanel = true;
            this.splitControl =
                Split(['#summary', '#details'], {
                    sizes: [50, 50],
                    direction: 'vertical',
                    gutterSize: 15,
                });
            this.contractsService.symbolSelected.emit(this.selectedSymbol);
            this.navTabService.saveTabPreferences(this.tabId, {showDetails: true});
        } else {
            this.showDetailsPanel = false;
            this.splitControl.destroy();
            Split(['#summary'], {
                sizes: [100],
                direction: 'vertical',
                gutterSize: 0,
            });
            this.navTabService.saveTabPreferences(this.tabId, {showDetails: false});
        }
    }


    autoSizeAll(event) {
        this.gridColumnApi = event.columnApi;
        const allColumnIds = [];
        const columns = this.gridColumnApi.getAllColumns();
        if (columns !== undefined) {
            this.gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.autoSizeColumns(allColumnIds);
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        // this.gridApi.dtcPositionService = this.dtcPositionService;
        this.contractsService.init = true;
        this.init = true;
        this.setupContractSubscriptions();
        this.onFilterChangedSubscription = this.filterService.filterChangedEvent.subscribe(filter => {
            let title: string;
            this.gridApi.setFilterModel(filter);
            if (filter.symbol && filter.symbol.values && filter.symbol.values.length > 0) {
                // this.selectedSymbol = filter.symbol.values[0].toUpperCase();
                // title = 'Contracts: ' + filter.symbol.values.toString().replace(/,/g, ' ') + ' ';
                // if (title.length > 30) {
                //     title = title.substr(0, 27) + '...';
                // }
                // this.navTabService.setTitle(this.tabId, title);
            } else if (filter.cusip && filter.cusip.values && filter.cusip.values.length > 0) {
                this.selectedSymbol = filter.cusip.values[0].toUpperCase();
                // title = 'Contracts: ' + filter.cusip.values.toString().replace(/,/g, ' ') + ' ';
                // if (title.length > 30) {
                //     title = title.substr(0, 27) + '...';
                // }
                // this.navTabService.setTitle(this.tabId, title);
            } else {
                this.selectedSymbol = '-';
                // this.navTabService.setTitle(this.tabId, 'Contracts');
            }
            this.navTabService.saveSelectedSymbol(this.tabId, this.selectedSymbol);
        });

        // this.dtcPositionService.initializeService(
        //     this.dtcPositionService.collectionName,
        //     this.dtcPositionService.createSummaryCallback
        // );

        let filter = this.navTabService.getFilter(this.tabId);
        if (filter) {
            this.gridApi.setFilterModel(filter);
            this.filterService.setFilterControls(filter);
            this.filterService.currentFilter = filter;
        } else {
            this.filterService.clearFilterControls(false);
        }
        this.aggregateContracts();
    }

    depositoryFilter(contract: Contract): boolean {
        return ((contract.depositoryNo === this.switcherService.getSelectedDepositoryNo()) );//&& (contract.contractStatusId === 7 || contract.contractStatusId === 85));
    }

    contractAdded(contracts: Contract[]) {
        if (this.gridApi) {
            const newRows: RowNode[] = [];
            const newContracts: Contract[] = [];
            for (const contract of contracts.filter(c => this.depositoryFilter(c))) {
                const rowNode = this.gridApi.getRowNode(contract.contractId);
                if (rowNode === undefined) {
                    newContracts.push(contract);
                }
            }
            this.gridApi.updateRowData({
                add: newContracts
            });
            for (const contract of newContracts) {
                const rowNode = this.gridApi.getRowNode(String(contract.primaryKey));
                newRows.push(rowNode);
            }
            this.aggregateContracts();
        }
    }

    contractChanged(contracts: Contract[]) {
        if (this.gridApi) {
            this.gridApi.updateRowData({
                update: contracts.filter(c => this.depositoryFilter(c))
            });
        }
        this.aggregateContracts();
    }

    contractRemoved(contracts: Contract[]) {
        if (this.gridApi) {
            this.gridApi.updateRowData({
                remove: contracts.filter(c => this.depositoryFilter(c))
            });
        }
        this.aggregateContracts();
    }

    setupContractSubscriptions() {
        this.gridApi.setRowData([]);
        this.gridApi.setRowData(this.contractsService.currentCollection.filter((c => this.depositoryFilter(c))));

        this.onContractsAddedSubscription =
            this.contractsService.onCollectionAdded.subscribe(contracts => this.contractAdded(contracts));

        this.onContractsChangedSubscription =
            this.contractsService.onCollectionChanged.subscribe(contracts => this.contractChanged(contracts));

        this.onContractsRemovedSubscription =
            this.contractsService.onCollectionRemoved.subscribe(contracts => this.contractRemoved(contracts));

        this.onUserDataChangedSubscription =
            this.contractsService.onUserDataChanged.subscribe(user => {
                this.user = user;
            });

        // this.onPositionModified =
        //     this.dtcPositionService.mapAlert
        //         .subscribe(
        //             () => {
        //                 this.gridApi.refreshClientSideRowModel('aggregate');
        //             }
        //         );


        let selectedSymbol = this.navTabService.getSelectedSymbol(this.tabId);
        if (selectedSymbol) {
            this.contractsService.symbolSelected.emit(selectedSymbol);
        } else {
            this.contractsService.symbolSelected.emit('-');
        }


    }

    onRowDoubleClicked(event) {
        if (event.node) {
            const symbol = (event.node.aggData || event.node.data).symbol || event.node.key;
            let navTabItem = new NavTabItem('contracts', symbol, 'item', 'list',
                '/contract-details', true, null, null,
                {
                    filter: {
                        symbol: {filterType: 'set', values: [symbol]}
                    }
                },
                {
                    symbol: {filterType: 'set', values: [symbol]}
                }, symbol
            );

            this.navTabService.addTab(navTabItem);
        }

        // {selectedSymbol: symbol}
    };

    groupContractAgg(nodes) {

        const result = {
            borrowRate: 0,
            loanRate: 0,
            lpitRate: undefined,
            spread: 0,
            borrowQuantity: 0,
            loanQuantity: 0,
            imbalance: 0,
            borrowAmount: 0,
            fxBorrowAmount: 0,
            fxLoanAmount: 0,
            loanAmount: 0,
            cash: 0,
            dailyRebate: 0,
            pendingBorrowRecall: 0,
            pendingLoanRecall: 0,
            pendingRecall: 0,
            pendingBorrowReturn: 0,
            pendingLoanReturn: 0,
            pendingReturn: 0,
            pending: 0,
            symbol: '',
            dtcQuantity: 0,
            madeNewBorrow: 0,
            madeNewLoan: 0,
            madeReturnBorrow: 0,
            madeReturnLoan: 0,
            borrowRecall: 0,
            loanRecall: 0,
            cusip: '',
            lpitBorrowSpread: undefined,
            lpitLoanSpread: undefined
        };
        nodes.forEach(function (node) {
            const data = node.group ? node.aggData : node.data;
            if (node.data) {
                result.cusip = data.cusip;
                if (data.contractStatus !== 'Warning' && data.contractStatus !== 'Open') {
                    return;
                }
                if (data.side === 'B') {
                    result.borrowRate += (data.borrowQuantity * data.rate);

                    result.pendingBorrowRecall += data.pendingRecall;
                    result.pendingBorrowReturn += data.pendingReturn;
                } else {
                    result.loanRate += (data.loanQuantity * data.rate);
                    result.pendingLoanRecall += data.pendingRecall;
                    result.pendingLoanReturn += data.pendingReturn;
                }
                //
                // if (node.gridApi.dtcPositionService) {
                //     const position = node.gridApi.dtcPositionService.getSummary(data.cusip, data.depositoryNo);
                //     result.dtcQuantity = position.quantity;
                //     result.madeNewBorrow = position.madeNewBorrow;
                //     result.madeNewLoan = position.madeNewLoan;
                //     result.madeReturnBorrow = position.madeReturnBorrow;
                //     result.madeReturnLoan = position.madeReturnLoan;
                // }


                // if (node.gridApi.lendingPitService) {
                //     const lpit = node.gridApi.lendingPitService.getByPrimaryKey(data.cusip);
                //     if (lpit) {
                //         result.lpitRate = lpit.rebateAvg;
                //     }
                // }

            } else {
                result.borrowRate += (data.borrowQuantity * data.borrowRate);
                result.loanRate += (data.loanQuantity * data.loanRate);
            }
            result.cash += data.cash;
            result.dailyRebate += data.dailyRebate;
            result.borrowQuantity += data.borrowQuantity;
            result.fxBorrowAmount += data.fxBorrowAmount;
            result.fxLoanAmount += data.fxLoanAmount;
            result.loanQuantity += data.loanQuantity;
            result.imbalance = result.borrowQuantity - result.loanQuantity;
            result.borrowAmount += data.borrowAmount;
            result.loanAmount += data.loanAmount;
            result.borrowRecall += data.borrowRecall;
            result.loanRecall += data.loanRecall;

            // result.pendingReturn += data.pendingReturn;

            result.symbol = data.symbol;
            result.lpitRate = data.rebateAvg;
        });
        // moved outside loop to correct summary bug
        result.pendingReturn += result.pendingBorrowReturn - result.pendingLoanReturn;
        result.pendingRecall += result.pendingLoanRecall - result.pendingBorrowRecall;
        // result.dailyRebate += result.dailyRebate;

        if (result.borrowRate !== 0) {
            result.borrowRate = result.borrowRate / (result.borrowQuantity);
        }
        if (result.loanRate !== 0) {
            result.loanRate = result.loanRate / (result.loanQuantity);
        }
        result.spread = result.loanRate - result.borrowRate;
        result.cash = result.loanAmount - result.borrowAmount;
        result.pending = result.pendingRecall - result.pendingReturn;


        if(result.lpitRate) {
            result.lpitBorrowSpread = result.borrowAmount * (result.lpitRate - result.borrowRate) / 360 * .01 * -1;
            result.lpitLoanSpread = result.loanAmount * (result.lpitRate - result.loanRate) / 360 * .01;
        }

        return result;
    }

    aggregateContracts() {
        const result = {
            borrowRate: 0,
            loanRate: 0,
            lpitRate: undefined,
            spread: 0,
            borrowQuantity: 0,
            loanQuantity: 0,
            imbalance: 0,
            borrowAmount: 0,
            fxBorrowAmount: 0,
            loanAmount: 0,
            fxLoanAmount: 0,
            cash: 0,
            dailyRebate: 0,
            pendingBorrowRecall: 0,
            pendingLoanRecall: 0,
            pendingRecall: 0,
            pendingBorrowReturn: 0,
            pendingLoanReturn: 0,
            pendingReturn: 0,
            pending: 0,
            symbol: '',
            dtcQuantity: 0,
            madeNewBorrow: 0,
            madeNewLoan: 0,
            madeReturnBorrow: 0,
            madeReturnLoan: 0,
            borrowRecall: 0,
            loanRecall: 0,
            lpitBorrowSpread: undefined,
            lpitLoanSpread: undefined
        };

        this.gridApi.forEachNodeAfterFilter(function (node, index) {
            if (node.group) {
                return;
            }
            const data = node.group ? node.aggData : node.data;
            if (node.data) {
                if (data.contractStatus !== 'Warning' && data.contractStatus !== 'Open') {
                    return;
                }
                if (data.side === 'B') {
                    result.borrowRate += (data.borrowQuantity * data.rate);
                    result.pendingBorrowRecall += data.pendingRecall;
                    result.pendingBorrowReturn += data.pendingReturn;
                } else {
                    result.loanRate += (data.loanQuantity * data.rate);
                    result.pendingLoanRecall += data.pendingRecall;
                    result.pendingLoanReturn += data.pendingReturn;
                }

                // if (node.gridApi.dtcPositionService) {
                //     const position = node.gridApi.dtcPositionService.getSummary(data.cusip, data.depositoryNo);
                //     result.dtcQuantity = position.quantity;
                //     result.madeNewBorrow = position.madeNewBorrow;
                //     result.madeNewLoan = position.madeNewLoan;
                //     result.madeReturnBorrow = position.madeReturnBorrow;
                //     result.madeReturnLoan = position.madeReturnLoan;
                // }

                // if (node.gridApi.lendingPitService) {
                //     const lpit = node.gridApi.lendingPitService.getByPrimaryKey(data.cusip);
                //     if (lpit) {
                //         result.lpitRate = lpit.rebateAvg;
                //     }
                // }

            } else {
                result.borrowRate += (data.borrowQuantity * data.borrowRate);
                result.loanRate += (data.loanQuantity * data.loanRate);
            }
            result.cash += data.cash;
            result.dailyRebate += data.dailyRebate;
            result.borrowQuantity += data.borrowQuantity;
            result.loanQuantity += data.loanQuantity;
            result.imbalance = result.borrowQuantity - result.loanQuantity;
            result.borrowAmount += data.borrowAmount;
            result.fxBorrowAmount += data.fxBorrowAmount;
            result.loanAmount += data.loanAmount;
            result.fxLoanAmount += data.fxLoanAmount;
            // result.pendingReturn += data.pendingReturn;
            result.symbol = data.symbol;
            result.borrowRecall += data.borrowRecall;
            result.loanRecall += data.loanRecall;
            result.lpitRate = data.rebateAvg;

        });
        // moved outside loop to correct summary bug
        result.pendingReturn += result.pendingBorrowReturn - result.pendingLoanReturn;
        result.pendingRecall += result.pendingLoanRecall - result.pendingBorrowRecall;
        // result.dailyRebate += result.dailyRebate;
        if (result.borrowRate !== 0) {
            result.borrowRate = result.borrowRate / (result.borrowQuantity);
        }
        if (result.loanRate !== 0) {
            result.loanRate = result.loanRate / (result.loanQuantity);
        }
        result.spread = result.loanRate - result.borrowRate;
        result.cash = result.loanAmount - result.borrowAmount;
        result.pending = result.pendingRecall - result.pendingReturn;

        result.lpitBorrowSpread = result.borrowAmount * (result.lpitRate - result.borrowRate) / 360 * .01 * -1;
        result.lpitLoanSpread = result.loanAmount * (result.lpitRate - result.loanRate) / 360 * .01;

        this.pinnedBottomRowData = result;
        this.updatePinnedRows();
    }

    updatePinnedRows() {
        const rows = [];
        rows.push({
            symbol: 'Total',
            borrowRate: this.pinnedBottomRowData.borrowRate,
            loanRate: this.pinnedBottomRowData.loanRate,
            spread: this.pinnedBottomRowData.spread,
            borrowQuantity: this.pinnedBottomRowData.borrowQuantity,
            loanQuantity: this.pinnedBottomRowData.loanQuantity,
            imbalance: this.pinnedBottomRowData.imbalance,
            borrowAmount: this.pinnedBottomRowData.borrowAmount,
            fxBorrowAmount: this.pinnedBottomRowData.fxBorrowAmount,
            fxLoanAmount: this.pinnedBottomRowData.fxLoanAmount,
            loanAmount: this.pinnedBottomRowData.loanAmount,
            cash: this.pinnedBottomRowData.cash,
            dailyRebate: this.pinnedBottomRowData.dailyRebate,
            pendingBorrowRecall: 0,
            pendingLoanRecall: 0,
            pendingRecall: this.pinnedBottomRowData.pendingRecall,
            pendingBorrowReturn: 0,
            pendingLoanReturn: 0,
            pendingReturn: this.pinnedBottomRowData.pendingReturn,
            pending: 0,
            dtcQuantity: 0,
            madeNewBorrow: 0,
            madeNewLoan: 0,
            madeReturnBorrow: 0,
            madeReturnLoan: 0,
            borrowRecall: this.pinnedBottomRowData.borrowRecall,
            loanRecall: this.pinnedBottomRowData.loanRecall,
            lpitBorrowSpread: undefined,
            lpitLoanSpread: undefined
        });

        this.gridApi.setPinnedBottomRowData(rows);
    }

    autoSize(event) {
        this.gridApi = event.api;
        this.gridApi.sizeColumnsToFit();
    }

    defineColumns() {
        this.columnDefs = [
            {
                headerName: 'Symbol', field: 'symbol', filterParams: {
                    defaultOption: 'equals', newRowsAction: 'keep'
                }, filter: 'agSetColumnFilter',
                sort: 'asc', rowGroup: true, showRowGroup: 'symbol', enableRowGroup: true, minWidth: 140,
                hide: true, resizable: true, pinned: true, valueFormatter: '', cellStyle: {'color': 'blue'},

            },
            {
                headerName: 'Cusip', field: 'cusip', minWidth: 100,
                width: 100, maxWidth: 100, filterParams: {
                    defaultOption: 'equals', newRowsAction: 'keep'
                }, filter: 'agSetColumnFilter', hide: false,
            },
            {
                headerName: 'Contra', field: 'counterPartyName', filterParams: {
                    defaultOption: 'contains', newRowsAction: 'keep'
                }, filter: 'agSetColumnFilter',
                showRowGroup: 'counterPartyName', enableRowGroup: true, hide: true, valueFormatter: ''
            },
            {
                headerName: 'L CNO.',
                field: 'contractNo',
                filter: 'agSetColumnFilter',
                filterParams: {newRowsAction: 'keep'},
                hide: true
            },
            {
                headerName: 'DTC', field: 'contraDepositoryNo', width: 80, suppressSizeToFit: true, filter: 'agSetColumnFilter',
                filterParams: {newRowsAction: 'keep'}, enableRowGroup: true, hide: true, valueFormatter: ''
            },
            {
                headerName: 'LoanetId', field: 'contraLoanetId', width: 80, suppressSizeToFit: true, filter: 'agSetColumnFilter',
                filterParams: {newRowsAction: 'keep'}, hide: true, valueFormatter: '',
            },
            {
                headerName: 'PRC', field: 'profitCenter', enableRowGroup: true, hide: true, valueFormatter: '', filter: 'agSetColumnFilter'
            },
            {
                headerName: 'ContraCurrency', field: 'contraCurrencyId', enableRowGroup: true, hide: true, valueFormatter: '', filter: 'agSetColumnFilter'
            },
            {
                headerName: 'Fee', field: 'fee', enableRowGroup: true, hide: true, valueFormatter: '', filter: 'agSetColumnFilter', type: 'numericColumn',
                minWidth: 78, maxWidth: 78, width: 78
            },
            {
                headerName: 'FxRate', field: 'contraFxRate', enableRowGroup: true, hide: true, valueFormatter: '', filter: 'agSetColumnFilter', type: 'numericColumn',
                minWidth: 78, maxWidth: 78, width: 78
            },
            {
                headerName: 'Rates',
                marryChildren: true,
                children: [
                    {
                        headerName: 'Borrow',
                        field: 'borrowRate',
                        type: 'numericColumn',
                        minWidth: 78,
                        maxWidth: 78,
                        width: 78,
                        cellStyle: {color: 'inherit'},
                        valueFormatter: (params) => {
                            if (params.value === undefined) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '');
                            }
                        },
                        filter: 'agNumberColumnFilter',
                        filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'Loan',
                        field: 'loanRate',
                        type: 'numericColumn',
                        minWidth: 78,
                        maxWidth: 78,
                        width: 78,
                        cellStyle: {color: 'inherit'},
                        valueFormatter: (params) => {
                            if (params.value === undefined) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '');
                            }
                        },
                        filter: 'agNumberColumnFilter',
                        filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'Spread', field: 'spread', type: 'numericColumn', minWidth: 78, maxWidth: 78, width: 78,
                        cellStyle: {color: 'inherit'},
                        valueFormatter: (params) => {
                            if (params.value === undefined) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '');
                            }
                        }, filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
                    },
                    // {
                    //     headerName: 'LPit', field: 'lpitRate', type: 'numericColumn', minWidth: 78, maxWidth: 78, width: 78,
                    //     cellStyle: CombinedContractsComponent.styleLendingPitCompare,
                    //     valueFormatter: (params) => {
                    //         if (params.value === undefined) {
                    //             return '';
                    //         } else {
                    //             return formatCurrency(params.value, this.locale, '');
                    //         }
                    //     },
                    //     filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
                    // },
                ]
            },
            {
                headerName: 'Amount',
                marryChildren: true,
                children: [

                    {
                        headerName: 'Borrow', field: 'borrowAmount', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 110, maxWidth: 110, width: 110,
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                            }
                        }, filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'FxBorrow', field: 'fxBorrowAmount', hide:true, type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 110, maxWidth: 110, width: 110,
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                            }
                        }, filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'Loan', field: 'loanAmount', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 110, maxWidth: 110, width: 110,
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                            }
                        }, filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'FxLoan', field: 'fxLoanAmount', hide: true, type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 110, maxWidth: 110, width: 110,
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                            }
                        }, filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'Cash', field: 'cash', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 110, maxWidth: 110, width: 110,
                        cellStyle: CombinedContractsComponent.styleNegZero,
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return '';
                            } else {
                                return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                            }
                        },

                        filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'Rebate', field: 'dailyRebate', type: 'numericColumn', filter: 'agNumberColumnFilter',
                        minWidth: 110, maxWidth: 110, width: 110, cellStyle: CombinedContractsComponent.styleNegZero,
                        valueFormatter: (params) => {
                            if (params.value === undefined) {
                                return '';
                            } else {
                                return formatCurrency(params.value, this.locale, '$');
                            }
                        }, filterParams: {newRowsAction: 'keep'}
                    }
                ]
            },
            {
                headerName: 'Quantity',
                marryChildren: true,
                children: [
                    {
                        headerName: 'Borrow', field: 'borrowQuantity', minWidth: 110, maxWidth: 110, width: 110,
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        }, type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'Loan', field: 'loanQuantity', minWidth: 110, maxWidth: 110, width: 110,
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        }, type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'Imbal.',
                        field: 'imbalance',
                        headerToolTip: 'Imbalance = (Borrow - Loan) quantity',
                        minWidth: 110, maxWidth: 110, width: 110,
                        cellStyle: CombinedContractsComponent.styleNegZero,
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        },
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        filterParams: {newRowsAction: 'keep'}

                    }
                ]
            },
            // {
            //     headerName: 'Lending Pit',
            //     marryChildren: true,
            //     children: [
            //
            //         {
            //             headerName: 'Borrow', field: 'lpitBorrowSpread', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 110, maxWidth: 110, width: 110,
            //             valueFormatter: (params) => {
            //                 if (params.value === undefined) {
            //                     return '';
            //                 } else {
            //                     return formatCurrency(params.value, this.locale, '$');
            //                 }
            //             }, filterParams: {newRowsAction: 'keep'}
            //         },
            //         {
            //             headerName: 'Loan', field: 'lpitLoanSpread', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 110, maxWidth: 110, width: 110,
            //             valueFormatter: (params) => {
            //                 if (params.value === undefined) {
            //                     return '';
            //                 } else {
            //                     return formatCurrency(params.value, this.locale, '$');
            //                 }
            //             }, filterParams: {newRowsAction: 'keep'}
            //         },
            //     ]
            // },
        ];

        // this.columnDefs.push(
        //     {
        //         headerName: 'DTC',
        //         marryChildren: true,
        //         children: [
        //             {
        //                 headerName: 'Qty', field: 'dtcQuantity',
        //                 type: 'numericColumn',
        //                 minWidth: 90, maxWidth: 90, width: 90,
        //                 valueFormatter: (params) => {
        //                     if (params.value === null) {
        //                         return 0;
        //                     } else {
        //                         return formatNumber(params.value, this.locale);
        //                     }
        //                 },
        //                 filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, cellStyle: CombinedContractsComponent.styleNegZero,
        //                 columnGroupShow: 'closed'
        //
        //             },
        //             {
        //                 headerName: 'NB', headerTooltip: 'Made New Borrow',
        //                 field: 'madeNewBorrow',
        //                 type: 'numericColumn',
        //                 minWidth: 90, maxWidth: 90, width: 90,
        //                 valueFormatter: (params) => {
        //                     if (params.value === null) {
        //                         return 0;
        //                     } else {
        //                         return formatNumber(params.value, this.locale);
        //                     }
        //                 },
        //                 filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, cellStyle: CombinedContractsComponent.styleNegZero,
        //                 columnGroupShow: 'closed'
        //             },
        //             {
        //                 headerName: 'NL', headerTooltip: 'Made New Loan',
        //                 field: 'madeNewLoan',
        //                 minWidth: 90, maxWidth: 90, width: 90,
        //                 type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, cellStyle: CombinedContractsComponent.styleNegZero,
        //                 columnGroupShow: 'closed',
        //                 valueFormatter: (params) => {
        //                     if (params.value === null) {
        //                         return 0;
        //                     } else {
        //                         return formatNumber(params.value, this.locale);
        //                     }
        //                 },
        //             },
        //             {
        //                 headerName: 'RB', headerTooltip: 'Made Return Borrow',
        //                 field: 'madeReturnBorrow',
        //                 type: 'numericColumn',
        //                 minWidth: 90, maxWidth: 90, width: 90,
        //                 valueFormatter: (params) => {
        //                     if (params.value === null) {
        //                         return 0;
        //                     } else {
        //                         return formatNumber(params.value, this.locale);
        //                     }
        //                 },
        //                 filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, cellStyle: CombinedContractsComponent.styleNegZero,
        //                 columnGroupShow: 'closed'
        //             },
        //             {
        //                 headerName: 'RL', headerTooltip: 'Made Return Loan',
        //                 field: 'madeReturnLoan',
        //                 type: 'numericColumn',
        //                 minWidth: 90, maxWidth: 90, width: 90,
        //                 valueFormatter: (params) => {
        //                     if (params.value === null) {
        //                         return 0;
        //                     } else {
        //                         return formatNumber(params.value, this.locale);
        //                     }
        //                 },
        //                 filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, cellStyle: CombinedContractsComponent.styleNegZero,
        //                 columnGroupShow: 'closed'
        //             }
        //         ]
        //     }
        // );

        this.columnDefs.push(
            {
                headerName: 'Activity',
                marryChildren: true,
                children: [
                    {
                        headerName: 'B Recall', field: 'borrowRecall', filter: 'agNumberColumnFilter',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        },
                        type: 'numericColumn',
                        minWidth: 90, maxWidth: 90, width: 90, cellStyle: CombinedContractsComponent.styleNegZero,
                        filterParams: {
                            defaultOption: 'greaterThan',
                            newRowsAction: 'keep'
                        }

                    },
                    {
                        headerName: 'L Recall', field: 'loanRecall', filter: 'agNumberColumnFilter',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        },
                        type: 'numericColumn',
                        minWidth: 90, maxWidth: 90, width: 90, cellStyle: CombinedContractsComponent.styleNegZero,
                        filterParams: {
                            defaultOption: 'greaterThan',
                            newRowsAction: 'keep'
                        }

                    },
                    {
                        headerName: 'Exposure', field: 'pendingRecall', filter: 'agNumberColumnFilter',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        },
                        type: 'numericColumn',
                        minWidth: 90, maxWidth: 90, width: 90, cellStyle: CombinedContractsComponent.styleNegZero,
                        filterParams: {
                            defaultOption: 'greaterThan',
                            newRowsAction: 'keep'
                        }

                    },
                    {
                        headerName: 'Return', field: 'pendingReturn', filter: 'agNumberColumnFilter', type: 'numericColumn',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        },
                        minWidth: 85, maxWidth: 85, width: 85, cellStyle: CombinedContractsComponent.styleNegZero,
                        filterParams: {
                            defaultOption: 'greaterThan', newRowsAction: 'keep'
                        }
                    },
                ]
            });

    }

    getRowNodeId(data) {
        return data.contractId;
    }

    toggleTradeWindow() {
        this.contractsService.toggleTradeWindow();
    }

    static styleZero(params) {
        if (params.value === 0) {
            return {'color': '#bfbfbf'};
        } else {
            return {'color': 'inherit'};
        }
    }

    static styleNegZero(params) {
        if (params.value === 0) {
            return {'color': '#bfbfbf'};
        } else if (params.value < 0) {
            return {'color': 'red'};
        } else if (params.value > 0) {
            return {'color': 'inherit'};
        }
    }

    static styleLendingPitCompare(params) {
        if (params.node.aggData) {
            if (((params.node.aggData.borrowRate < params.node.aggData.lpitRate) && params.node.aggData.borrowQuantity > 0 && params.node.aggData.borrowRate < 0) ||
                ((params.node.aggData.loanRate > params.node.aggData.lpitRate) && params.node.aggData.loanQuantity > 0 && params.node.aggData.loanRate < 0)) {
                return {'background': '#FFE4E2'};
            }
        }
    }

    // static styleLendingPitLoanCompare(params) {
    //     if (params.node.aggData) {
    //         if ((params.node.aggData.loanRate > params.node.aggData.lpitRate) && params.node.aggData.loanQuantity > 0) {
    //             return {'background': '#FFE4E2'};
    //         }
    //     }
    // }

    ngOnDestroy() {
        this.onContractsAddedSubscription.unsubscribe();
        this.onContractsChangedSubscription.unsubscribe();
        this.onContractsRemovedSubscription.unsubscribe();
        this.onUserDataChangedSubscription.unsubscribe();
        //this.onPositionModified.unsubscribe();
        this.onDepositoryNoSwitchedSubscription.unsubscribe();
        this.onFilterChangedSubscription.unsubscribe();
        this.onEffectiveDateChanged.unsubscribe();
        this.onShowDetailsSubscription.unsubscribe();
        this.gridApi.setRowData([]);
        this.gridApi.destroy();
    }

    onFilterChanged(event) {
        this.localPreferenceChange();
        this.aggregateContracts();
    }

    onRowDataChanged(event) {
        if (this.gridApi) {
            this.aggregateContracts();
        }
    }

    onRowSelectionChanged(event) {
        this.selectedSymbol = (event.node.aggData || event.node.data).symbol || event.node.key;
        this.contractsService.symbolSelected.emit(this.selectedSymbol);
        this.navTabService.saveSelectedSymbol(this.tabId, this.selectedSymbol);
        this.contractsService.selectedContracts = [];
    }

    setState(prefs: ContractDetailPreferences): void {
        if (this.gridColumnApi && this.gridApi) {
            if (prefs) {
                if (prefs.state) {
                    this.gridColumnApi.setColumnState(prefs.state);
                }

                if (prefs.sort) {
                    this.gridApi.setSortModel(prefs.sort);
                }

                if (prefs.filter) {
                    this.gridApi.setFilterModel(prefs.filter);
                }
                this.gridApi.onSortChanged();
                this.gridApi.onFilterChanged();
            }
        }
    }

    localPreferenceChange() {
        const filter = this.gridApi.getFilterModel();
        this.navTabService.saveFilter(this.tabId, filter);
    }
}

