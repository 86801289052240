import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AngularFirestore } from "angularfire2/firestore";
import { AngularFireAuth } from "angularfire2/auth";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { GridApi } from "ag-grid-community";
import { QueryFn } from "angularfire2/firestore/interfaces";

export interface Identifiable {
    primaryKey: any;
}

@Injectable({
    providedIn: "root",
})
export class FirestoreCollectionService<T extends Identifiable> {
    @Output() onSubscriptionQueryChanged = new EventEmitter();

    private createCallback: (newObj: any) => T;

    public collectionData: T[];

    /**
     * Whenever collectionData changes, emits the array.
     */
    currentCollectionArray: BehaviorSubject<any> = new BehaviorSubject([]);

    /**
     *  collectionChanged will emit just the events changed
     */
    onCollectionChanged: BehaviorSubject<T[]> = new BehaviorSubject([]);

    /**
     * collectionAdded will emit an array of new objects added.
     */
    onCollectionAdded: BehaviorSubject<T[]> = new BehaviorSubject([]);

    /**
     * collectionRemoved will emit the elements that were removed
     */
    onCollectionRemoved: BehaviorSubject<T[]> = new BehaviorSubject([]);

    /**
     * private observers/subscriptions on the collection
     */

    private collectionAddedObs: Observable<any>;
    private collectionChangedObs: Observable<any>;
    private collectionRemovedObs: Observable<any>;

    private collectionAddedSub: Subscription;
    private collectionChangedSub: Subscription;
    private collectionRemovedSub: Subscription;

    private componentGridApi: GridApi;

    constructor(
        private http: HttpClient,
        private firestore: AngularFirestore,
        private afAuth: AngularFireAuth,
    ) {}

    public initializeService(
        collection: string,
        createCallback,
        queryFn?: QueryFn,
    ): void {
        this.createCallback = createCallback;
        this.collectionData = [];
        this.afAuth.auth.onAuthStateChanged((user) => {
            if (user) {
                this.getFirestoreCollection(collection, queryFn);
            } else {
                this.clearSubscriptions();
            }
        });
    }

    public addComponentGridApi(gridApi: GridApi): void {
        this.componentGridApi = gridApi;
    }

    protected getFirestoreCollection(
        collection: string,
        queryFn: QueryFn,
    ): void {
        this.collectionChangedObs = this.firestore
            .collection(collection, queryFn)
            .stateChanges(["modified"]);
        this.collectionChangedSub = this.collectionChangedObs.subscribe(
            (snapshot) => {
                const modifiedArr: T[] = [];
                for (const snapshotAction of snapshot) {
                    const primaryKey = snapshotAction.payload.doc.id;
                    const data = snapshotAction.payload.doc.data();
                    data.primaryKey = primaryKey;
                    const newObject: T = this.createCallback(data);
                    const indexToUpdate = this.getIndexByPrimaryKey(
                        newObject.primaryKey,
                    );
                    if (indexToUpdate !== -1) {
                        this.collectionData[indexToUpdate] = newObject;
                        modifiedArr.push(newObject);
                    }
                }
                if (this.componentGridApi) {
                    this.componentGridApi.updateRowData({
                        update: modifiedArr,
                    });
                }
                this.onCollectionChanged.next(modifiedArr);
                this.currentCollectionArray.next(this.collectionData);
            },
        );

        this.collectionAddedObs = this.firestore
            .collection(collection, queryFn)
            .stateChanges(["added"]);
        this.collectionAddedSub = this.collectionAddedObs.subscribe(
            (snapshot) => {
                const collectionArr = [];
                for (const snapshotAction of snapshot) {
                    const primaryKey = snapshotAction.payload.doc.id;
                    const data = snapshotAction.payload.doc.data();
                    data.primaryKey = primaryKey;
                    const newObject: T = this.createCallback(data);
                    this.collectionData.push(newObject);
                    collectionArr.push(newObject);
                }
                if (this.componentGridApi) {
                    this.componentGridApi.updateRowData({ add: collectionArr });
                }
                this.onCollectionAdded.next(collectionArr);
                this.currentCollectionArray.next(this.collectionData);
            },
        );

        this.collectionRemovedObs = this.firestore
            .collection(collection, queryFn)
            .stateChanges(["removed"]);
        this.collectionRemovedSub = this.collectionRemovedObs.subscribe(
            (snapshot) => {
                const removedArr: T[] = [];
                for (const snapshotAction of snapshot) {
                    const primaryKey = snapshotAction.payload.doc.id;
                    const data = snapshotAction.payload.doc.data();
                    data.primaryKey = primaryKey;
                    const removeObject = this.createCallback(data);
                    this.removeFromCollectionList(removeObject);
                    removedArr.push(removeObject);
                }
                if (this.componentGridApi) {
                    this.componentGridApi.updateRowData({ remove: removedArr });
                    this.componentGridApi.flashCells();
                }
                this.onCollectionRemoved.next(removedArr);
                this.currentCollectionArray.next(this.collectionData);
            },
        );
    }

    public getIndexByPrimaryKey(primaryKey: any): number {
        let index = 0;
        for (const obj of this.collectionData) {
            if (obj.primaryKey === primaryKey) {
                return index;
            }
            index++;
        }
        return -1;
    }

    public getByPrimaryKey(primaryKey: any): T {
        let index = 0;
        for (const obj of this.collectionData) {
            if (obj.primaryKey === primaryKey) {
                return obj;
            }
            index++;
        }
        return null;
    }

    private removeFromCollectionList(objectToRemove: T) {
        let index = 0;
        for (const obj of this.collectionData) {
            if (obj.primaryKey === objectToRemove.primaryKey) {
                this.collectionData.splice(index);
                return;
            }
            index++;
        }
        return;
    }

    public clearSubscriptions(): void {
        if (this.collectionAddedSub) this.collectionAddedSub.unsubscribe();
        this.collectionChangedSub.unsubscribe();
        this.collectionRemovedSub.unsubscribe();
        this.componentGridApi = undefined;
        this.collectionData = [];
        this.onSubscriptionQueryChanged.emit();
    }

    public changeSubscriptionQuery(collection: string, queryFn: QueryFn) {
        this.clearSubscriptions();
        this.getFirestoreCollection(collection, queryFn);
    }
}
