import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../auth/auth.guard';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule, MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule, MatSidenavModule,
    MatTabsModule,
} from '@angular/material';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {AgGridModule} from 'ag-grid-angular';
import {SharedModule} from '../../../../shared';
import {ShortInterestRatesService} from './shortInterestRates.service';
import {ShortInterestRatesComponent} from './shortInterestRates.component';
import {ShortInterestRatesListComponent} from './short-interest-rates-list.component';

const routes: Routes = [
    {
        path: 'shortInterestRates',
        component: ShortInterestRatesComponent,
        canActivate: [AuthGuard],
    }
];


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatIconModule,
        FuseSharedModule,
        MatCardModule,
        MatProgressBarModule,
        AgGridModule.withComponents([]),
        MatTabsModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatSidenavModule,
        MatDividerModule,
        SharedModule,
        MatAutocompleteModule
    ],
    declarations: [
        ShortInterestRatesComponent,
        ShortInterestRatesListComponent
    ],
    providers: [
        ShortInterestRatesService
    ],
    exports: [
        ShortInterestRatesComponent,
    ],
    entryComponents: []
})
export class ShortInterestRatesModule {
}
