import {EventEmitter, Injectable, Output} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {AngularFirestore} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';
import {ShortInterestRate} from './shortInterestRates.model';
import moment from 'moment';
import {RowNode} from 'ag-grid-community';

@Injectable()
export class ShortInterestRatesService implements Resolve<any> {

    endpoint = environment.verticalApiEndpoint;
    shortInterestRates;
    editedShortRates: ShortInterestRate[] = [];

    constructor(private httpClient: HttpClient) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getShortRates()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getShortRates(): Promise<ShortInterestRate[]> {
        return new Promise((resolve, reject) => {
            this.httpClient.get(this.endpoint + 'shortInterestRates/get')
                .subscribe((response: any) => {
                    this.shortInterestRates = [];
                    for (const shortRate of response.items) {
                        this.shortInterestRates.push(new ShortInterestRate(shortRate));
                    }
                    resolve(this.shortInterestRates);
                }, reject);
        });
    }

    saveShortRates(): Promise<ShortInterestRate> {

        const json = {
            items: this.editedShortRates
        };


        return new Promise((resolve, reject) => {
            this.httpClient.post(this.endpoint + 'shortInterestRates/update', json)
                .subscribe((response: any) => {
                    this.shortInterestRates = [];
                    this.editedShortRates = [];
                    for (const shortRate of response.items) {
                        this.shortInterestRates.push(new ShortInterestRate(shortRate));
                    }
                    resolve(this.shortInterestRates);
                }, reject);
        });
    }


}
