import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {Contract} from './contract.model';
import {environment} from '../../../../../environments/environment';
import {AngularFirestore, QueryFn} from 'angularfire2/firestore';
import {MatSnackBar} from '@angular/material';
import {AngularFireAuth} from 'angularfire2/auth';
import {QuoteService} from '../../instruments/quotes/quote.service';
import * as moment from 'moment';
import {Moment} from 'moment';
import {FirestoreCollectionService} from '../../../../shared/firestore-collection.service';
import {GridApi} from 'ag-grid-community';
import firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {TradeFormMode, TradeFormParams} from '../trade-form/TradeFormParams';
import {QuickSymbolSummaryService} from '../quick-symbol-summary/quickSymbolSummaryService';
import {ConfirmationDialogComponent} from '../../../../shared/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';

export interface ProfitLoss {
    funding: number;
    rebate: number;
}

@Injectable()
export class ContractsService extends FirestoreCollectionService<Contract> {
    @Output() showTradePanel = new EventEmitter();
    @Output() showQuickSymbolSummaryPanel = new EventEmitter();
    onSelectedContractsChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    onUserDataChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    onFilterChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    onEffectiveDateChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    selectedContracts: Contract[] = [];

    @Output() symbolSelected = new EventEmitter();
    @Output() onNewContractArray = new EventEmitter();

    gridApi: GridApi;
    gridColumnApi: any;
    init = false;
    showTradeForm = false;
    showQuickSymbolSummary = false;
    isLoading = false;

    effectiveDate: Moment; // = moment();
    maxDate: Moment; // = moment();
    allowInput = true;
    collectionName = 'contract-summary';
    historicalCollection = [];
    currentCollection = [];
    dialogRef: any;

    readonly endpoint = environment.verticalApiEndpoint + 'contractTimeline';

    constructor(private httpClient: HttpClient,
                private firestoreClient: AngularFirestore,
                private afAuthClient: AngularFireAuth,
                private quoteService: QuoteService,
                public snackBar: MatSnackBar,
                public switcherService: SwitcherService,
                public quickSymbolService: QuickSymbolSummaryService,
                public dialog: MatDialog) {
        super(httpClient, firestoreClient, afAuthClient);
        super.initializeService(this.collectionName, this.createContractCallback);// ref => ref.where('depositoryNo', '==', this.switcherService.getSelectedDepositoryNo()));
        this.effectiveDate = moment();

        this.maxDate = moment();
        this.currentCollection = this.collectionData;
        // this.onDepositoryNoChanged = this.switcherService.depositoryNoSwitched.subscribe(depositoryNo => this.changeSubscriptionQuery(this.collectionName, ref => ref.where('depositoryNo', '==', depositoryNo)));
    }

    changeSubscriptionQuery(collection: string, queryFn: QueryFn) {
        super.changeSubscriptionQuery(collection, queryFn);
    }

    public createContractCallback(newObj: any): Contract {
        return new Contract(newObj);
    }

    public toggleTradeWindow(params?: TradeFormParams) {

        let parameters;
        if (params) {
            parameters = params;
        } else {
            parameters = new TradeFormParams(TradeFormMode.Normal);
        }
        this.showQuickSymbolSummary = false;
        this.showTradeForm = true;
        this.showTradePanel.emit(parameters);
    }


    public toggleQuickSymbolSummaryWindow(params: string) {
        this.quickSymbolService.getQuickSymbolSummary(params).then(reponse => {
            this.showQuickSymbolSummary = true;
            this.showTradeForm = false;
        });
    }


    getContractListDatabase() {
        const httpOptions = {
            params: {
                date: this.effectiveDate.format()
            }
        };
        this.httpClient.get(this.endpoint, httpOptions)
            .subscribe((resp: any) => {
                //console.log('resp for list db: ', resp);
                const contractsArr = [];

                // I have to rebuild a new contract array for some unknown reason to push updates..
                // //console.log('CONTRACTS: ', resp.items);
                for (const contract of resp.items) {
                    contract.startOn = new Timestamp(new Date(contract.startOn).getTime() / 1000, 0);
                    const newContract = new Contract(contract);
                    newContract.primaryKey = newContract.contractId;
                    contractsArr.push(newContract);
                }
                this.collectionData = contractsArr;
                this.onCollectionAdded.next(contractsArr);
                this.currentCollectionArray.next(this.collectionData);
                this.onNewContractArray.emit(this.historicalCollection);
            }, (err) => {
                //console.log('err getting list db: ', err);
            });
    }


    getHistoricalContractList() {
        const httpOptions = {
            params: {
                date: this.effectiveDate.format()
            }
        };
        this.onNewContractArray.emit([]);
        this.httpClient.get(this.endpoint, httpOptions)
            .subscribe((resp: any) => {
                const contractsArr = [];
                // I have to rebuild a new contract array for some unknown reason to push updates..
                // //console.log('CONTRACTS: ', resp.items);
                for (const contract of resp.items) {
                    contract.startOn = new Timestamp(new Date(contract.startOn).getTime() / 1000, 0);
                    contract.buyInDate = contract.buyInDate ? new Timestamp(new Date(contract.buyInDate).getTime() / 1000, 0) : null;
                    const newContract = new Contract(contract);
                    newContract.primaryKey = newContract.contractId;
                    contractsArr.push(newContract);
                }
                this.historicalCollection = contractsArr;
                this.currentCollection = this.historicalCollection;
                this.onNewContractArray.emit(this.currentCollection);
            }, (err) => {
                //console.log('err getting list db: ', err);
            });
    }

    //
    //
    // getContractsByDate():Contract[] {
    //     const httpOptions = {
    //         params: {
    //             date: this.effectiveDate.format()
    //         }
    //     };
    //     this.httpClient.get(this.endpoint, httpOptions)
    //         .subscribe((resp: Contract[]) => {
    //             const contractsArr = [];
    //
    //             for (const contract of resp.items) {
    //                 contract.startOn = new Timestamp(new Date(contract.startOn).getTime() / 1000, 0);
    //                 const newContract = new Contract(contract);
    //                 newContract.primaryKey = newContract.contractId;
    //                 contractsArr.push(newContract);
    //             }
    //             this.historicalCollection = contractsArr;
    //             this.onNewContractArray.emit(this.historicalCollection);
    //             //console.log(this.historicalCollection);
    //         }, (err) => {
    //             //console.log('err getting list db: ', err);
    //         });
    //
    //     return null;
    // }

    setSelected(contracts: Contract[]): void {
        this.selectedContracts = contracts;
    }

    getSelected(): Contract[] {
        return this.selectedContracts;
    }

    getContractById(arr: Contract[], contractId: number): number {
        let index = 0;
        for (const contract of arr) {
            if (contract.contractId === contractId) {
                return index;
            }
            index++;
        }
        return null;
    }


    selectContracts(filterParameter?, filterValue?) {
        this.selectedContracts = [];

        // If there is no filter, select all todos
        if (filterParameter === undefined || filterValue === undefined) {
            this.selectedContracts = [];
            this.collectionData.map(contract => {
                this.selectedContracts.push(contract);
            });
        } else {
            /* this.selectedContracts.push(...
                 this.contracts.filter(todo => {
                     return todo[filterParameter] === filterValue;
                 })
             );*/
        }

        // Trigger the next eventName
        this.onSelectedContractsChanged.next(this.selectedContracts);
    }

    updateContract(contract) {
        //console.log('Posting contract: ', contract);
        return new Promise((resolve, reject) => {
            this.httpClient.post(environment.verticalApiEndpoint + 'contracts', contract)
                .subscribe(response => {
                    //console.log('Response to post: ', response);
                    resolve(response);
                }, (error) => {
                    //console.log('error: ', error);
                    reject(error);
                });
        });
    }

    updateFplContract(contract) {
        //console.log('Posting contract: ', contract);
        return new Promise((resolve, reject) => {
            this.httpClient.post(environment.verticalApiEndpoint + 'contracts', contract)
                .subscribe(response => {
                    //console.log('Response to post: ', response);
                    resolve(response);
                }, (error) => {
                    //console.log('error: ', error);
                    reject(error);
                });
        });
    }

    public deselectContracts() {
        this.selectedContracts = [];
        this.gridApi.deselectAll();
        // Trigger the next eventName
        this.onSelectedContractsChanged.next(this.selectedContracts);
    }

    reRateSelected(rate: number) {
        const contractArray = [];
        const endpoint = environment.verticalApiEndpoint + 'contracts/reRate';
        //console.log('post to: ', endpoint);
        for (const contract of this.selectedContracts) {
            contractArray.push(contract.contractId);
        }
        const json = {
            rate: rate,
            contractIds: contractArray
        };
        this.httpClient
            .post(endpoint, JSON.stringify(json))
            .subscribe((resp) => {
                //console.log('response: ', resp);
            }, (error) => {
                //console.log('error: ', error);
                this.snackBar.open(error.error.error.message, 'Dismiss');
            });
    }

    updateFxRate(rate: number) {
        const endpoint = environment.verticalApiEndpoint + 'contracts/updateFxRateAll';
        const json = {
            contraCurrencyId: 'MXN',
            rate: rate
        };
        this.httpClient
            .post(endpoint, JSON.stringify(json))
            .subscribe((resp) => {
            }, (error) => {
                //console.log('error: ', error);
                this.snackBar.open(error.error.error.message, 'Dismiss');
            });
    }

    return(contractId: number, returnQty: number, specFlag: string, batchCode: string, parentReturnId: number) {
        // const contractId = this.selectedContracts[0].contractId;
        const endpoint = environment.verticalApiEndpoint + 'contracts/' + contractId + '/return';
        //console.log('post to: ', endpoint);
        const json = {
            returnQty: returnQty,
            specFlag: specFlag,
            batchCode: batchCode,
            parentReturnId: parentReturnId
        };
        //console.log('object: ', JSON.stringify(json));
        this.httpClient
            .post(endpoint, JSON.stringify(json))
            .subscribe((resp) => {
                //console.log('response: ', resp);
            }, (error) => {
                //console.log('error: ', error);
                this.snackBar.open(error.error.error.message, 'Dismiss');
            });
    }

    recall(recallQty: number) {
        const contractId = this.selectedContracts[0].contractId;
        const endpoint = environment.verticalApiEndpoint + 'contracts/' + contractId + '/recall';
        //console.log('post to: ', endpoint);
        const json = {
            recallQty: recallQty
        };
        this.httpClient
            .post(endpoint, JSON.stringify(json))
            .subscribe((resp) => {
                //console.log('response: ', resp);
            }, (error) => {
                //console.log('error: ', error);
                this.snackBar.open(error.error.error.message, 'Dismiss');
            });
    }

    exportDataAsCsv(params: any) {
        this.gridApi.exportDataAsCsv(params);
    }

    getContractsWithCusip(cusip: string, side: string): Contract[] {
        const contractWithSymbol = [];
        //console.log('searching for contract with cusip: ', cusip);
        for (const contract of this.collectionData) {
            if (contract.cusip === cusip && contract.side === side) {
                contractWithSymbol.push(contract);
            }
        }
        return contractWithSymbol;
    }

    setEffectiveDate(event) {
        // // //console.log('set effective date event: ', event);
        // this.effectiveDate = event.value;
        // // //console.log(this.effectiveDate + ' = ' + this.maxDate + '? ', this.effectiveDate.isSame(this.maxDate, 'day'));
        // if (this.gridApi) {
        //     this.collectionData = [];
        //     this.gridApi.setRowData([]);
        //     this.gridApi.refreshCells();
        // }
        // if (this.isEffectiveDateToday()) {
        //     this.allowInput = true;
        //     this.initializeService(this.collectionName, this.createContractCallback);
        // } else {
        //     this.allowInput = false;
        //     this.clearSubscriptions();
        //     this.getContractListDatabase();
        // }
        // if (this.quoteService.quote) {
        //     const symbol = this.quoteService.quote.symbol;
        //     this.quoteService.updateQuote(symbol, this.effectiveDate);
        // }
        // this.onEffectiveDateChanged.next(this.allowInput);

        this.effectiveDate = event.value;

        if (this.isEffectiveDateToday()) {
            this.allowInput = true;
            this.currentCollection = this.collectionData;
            this.onNewContractArray.emit(this.currentCollection);
        } else {
            this.allowInput = false;
            this.getHistoricalContractList();
        }
        if (this.quoteService.quote) {
            const symbol = this.quoteService.quote.symbol;
            this.quoteService.updateQuote(symbol, this.effectiveDate);
        }
        this.onEffectiveDateChanged.next(this.allowInput);
    }

    isEffectiveDateToday(): boolean {
        return this.effectiveDate.isSame(moment(), 'day');
    }

    // toggleTradeWindow() {
    //     if (this.effectiveDate.isSame(this.maxDate, 'day')) {
    //         this.showTradeForm = !this.showTradeForm;
    //     }
    // }

    profitCenterAdjust(contract: Contract, newProfitCenter: string): void {
        // contracts/{contractId}/pcadj
        const contractId = contract.contractId;
        const endpoint = environment.verticalApiEndpoint + 'contracts/' + contractId + '/pcadj';
        //console.log('post to: ', endpoint);
        const json = {
            profitCenter: newProfitCenter
        };
        //console.log(JSON.stringify(json));
        this.httpClient
            .post(endpoint, JSON.stringify(json))
            .subscribe((resp) => {
                //console.log('response: ', resp);
            }, (error) => {
                //console.log('error: ', error);
                this.snackBar.open(error.error.error.message, 'Dismiss');
            });
    }

    updatePrivateComment(contract: Contract, comment: string): void {
        const id = contract.contractId;
        const endpoint = environment.verticalApiEndpoint + 'contracts/updatePrivateComment';
        //console.log('post to: ', endpoint);
        const json = {
            contractId: id,
            comment: comment
        };
        this.httpClient
            .post(endpoint, JSON.stringify(json))
            .subscribe((resp) => {
                //console.log('response: ', resp);
            }, (error) => {
                //console.log('error: ', error);
                this.snackBar.open(error.error.error.message, 'Dismiss');
            });
    }

    calculateProfitLoss(borrowQuantityTotal: number, loanQuantityTotal: number,
                        borrowAmountTotal: number, loanAmountTotal: number,
                        borrowRate: number, loanRate: number): ProfitLoss {

        const fundingCharge = this.calculateFunding(
            borrowQuantityTotal, loanQuantityTotal,
            borrowAmountTotal, loanAmountTotal,
            borrowRate, loanRate
        );

        const dailyRebate = this.calculateRebate(
            borrowQuantityTotal, loanQuantityTotal,
            borrowAmountTotal, loanAmountTotal,
            borrowRate, loanRate
        );

        return {
            funding: fundingCharge,
            rebate: dailyRebate
        };
    }

    private calculateRebate(borrowQuantityTotal: number, loanQuantityTotal: number,
                            borrowAmountTotal: number, loanAmountTotal: number,
                            borrowRate: number, loanRate: number): number {
        const borrPrice = borrowAmountTotal / borrowQuantityTotal;
        const loanPrice = loanAmountTotal / loanQuantityTotal;
        const matchShares = Math.min(borrowQuantityTotal, loanQuantityTotal);
        const borrMatchValue = matchShares * borrPrice;
        const loanMatchValue = matchShares * loanPrice;

        let borrRebate = Math.abs(
            (borrMatchValue * (borrowRate / 100)) / 360
        );

        if (borrowRate < 0) {
            borrRebate = borrRebate * -1;
        }

        let loanRebate = Math.abs(
            (loanMatchValue * (loanRate / 100)) / 360
        );

        if (loanRate > 0) {
            loanRebate = loanRebate * -1;
        }
        let dailyRebate = (borrRebate + loanRebate);
        if (isNaN(dailyRebate)) {
            dailyRebate = 0;
        }
        return dailyRebate;
    }

    approveContractsByIds(approved: boolean, contractIds: number[]) {
        var self = this;
        const action = approved ? 'Approve' : 'Deny';
        const endpoint = environment.verticalApiEndpoint + 'contracts/bulkapproval';
        const json = {'contractIds': contractIds, 'approved': approved};


        this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '300px',
            height: '200px',
            data: {
                dialogTitle: action + ' contract?',
                dialogText: 'Are you sure you want to ' + action + (contractIds.length > 1 ? ' (' + contractIds.length + ') contracts?' : ' \contract?')
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: boolean) => {
                if (response) {
                    self.httpClient
                        .post(endpoint, JSON.stringify(json))
                        .subscribe((resp) => {
                                this.snackBar.open('Submitted Successfully', 'OK', {
                                    verticalPosition: 'bottom',
                                    duration: 2000
                                });
                                //this.gridApi.deselectAll();
                            }, (error) => {
                                //console.log('error: ', error);
                            }
                        );
                }
            });


    }


    markForBuyIn(contractId: number, buyInQuantity: number) {
        var self = this;
        const endpoint = environment.verticalApiEndpoint + 'contracts/markForBuyIn';
        const json = {'contractId': contractId, 'buyInQuantity': buyInQuantity};
        self.httpClient.post(endpoint, JSON.stringify(json))
            .subscribe((resp) => {
                    this.snackBar.open('Submitted Successfully', 'OK', {
                        verticalPosition: 'bottom',
                        duration: 2000
                    });
                    //this.gridApi.deselectAll();
                }, (error) => {
                    //console.log('error: ', error);
                }
            );
    }


    approveContracts(approved: boolean) {
        var contractIds = [];
        this.selectedContracts.forEach(function (contract) {
            contractIds.push(contract.contractId);
        });

        this.approveContractsByIds(approved, contractIds);
    }

    updateDTCStatus(contractIds: number[], made: boolean) {
        var self = this;
        const endpoint = environment.verticalApiEndpoint + 'contracts/updateDtcStatus';

        let status = made ? 'M' : 'P';
        const json = {'contractIds': contractIds, 'dtcStatus': status};
        //console.log(json);

        self.httpClient
            .post(endpoint, JSON.stringify(json))
            .subscribe((resp) => {
                }, (error) => {
                    //console.log('error: ', error);
                }
            );

    }

    private calculateFunding(borrowQuantityTotal: number, loanQuantityTotal: number,
                             borrowAmountTotal: number, loanAmountTotal: number,
                             borrowRate: number, loanRate: number): number {
        const fundingRate = 0.025;

        const fundingDollars = borrowAmountTotal - loanAmountTotal;

        const fundingCharge = (borrowAmountTotal > loanAmountTotal) ? ((fundingDollars * fundingRate) / 360) * -1 : 0;

        const fundingRebateRate = (borrowAmountTotal > loanAmountTotal) ? borrowRate : loanRate;

        const debit = (fundingDollars * (fundingRebateRate / 100)) / 360;

        let totalFunding = fundingCharge + debit;
        if (isNaN(totalFunding)) {
            totalFunding = 0;
        }
        return totalFunding;
    }
}
